import React from 'react';
import FileCopy from '@mui/icons-material/FileCopy';
import { Badge } from '@mui/material';
import PropTypes from 'prop-types';

const DownloadExcelIcon = ({ badgeContent, ...props }) => (
  <Badge
    badgeContent={badgeContent}
    sx={{ '& .MuiBadge-badge': { fontSize: 9, height: 15, minWidth: 15 } }}
  >
    <FileCopy {...props} />
  </Badge>
);

DownloadExcelIcon.propTypes = {
  badgeContent: PropTypes.string,
};

DownloadExcelIcon.defaultProps = {
  badgeContent: 'xls',
};

export default DownloadExcelIcon;
