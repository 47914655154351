import React from 'react';
import PropTypes from 'prop-types';
import { Button, Typography } from '@mui/material';
import Publish from '@mui/icons-material/Publish';

const UploadFileInput = ({ onChange, file, label }) => (
  <>
    <Typography htmlFor="contained-button-file" mb={1}>
      {label}
    </Typography>
    <input
      accept=".pem, .p12, .pfx"
      style={{ display: 'none' }}
      id="contained-button-file"
      type="file"
      onChange={onChange}
    />
    <label htmlFor="contained-button-file">
      <Button fullWidth variant="outlined" component="span" endIcon={<Publish color="primary" />}>
        {file ? file.name : 'Selecciona un archivo'}
      </Button>
    </label>
  </>
);

UploadFileInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  file: PropTypes.any,
  label: PropTypes.string.isRequired,
};

UploadFileInput.defaultProps = {
  file: null,
};

export default UploadFileInput;
