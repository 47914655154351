import React from 'react';
import PropTypes from 'prop-types';
import { InputBase } from '@mui/material';
import { green } from '@mui/material/colors';
import Check from '@mui/icons-material/Check';
import Upload from '@mui/icons-material/Upload';
import LoadingIconButton from '../icon-buttons/LoadingIconButton';

const FileInput = ({ accept, loading, value, onChange, ...props }) => (
  <InputBase
    fullWidth
    readOnly
    value={value ? 'Archivo subido correctamente' : ''}
    endAdornment={(
      <LoadingIconButton loading={loading} component="label" disabled={loading}>
        {value ? (
          <Check sx={{ color: green[500] }} />
        ) : (
          <Upload color="primary" />
        )}
        <input type="file" hidden accept={accept} onChange={onChange} />
      </LoadingIconButton>
    )}
    {...props}
    sx={{
      ...props.sx,
      height: 45,
      border: '1px solid #ccc',
      borderRadius: 2,
      backgroundColor: '#F9F9F9',
      px: '5px',
      pl: 1.5,
    }}
  />
);

FileInput.propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.bool,
  ]),
  onChange: PropTypes.func.isRequired,
  accept: PropTypes.string,
  loading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  sx: PropTypes.object,
};

FileInput.defaultProps = {
  value: null,
  accept: '',
  loading: false,
  sx: {},
};

export default FileInput;
