import React from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { useTheme } from '@mui/material';
import PropTypes from 'prop-types';

const TourGuide = ({ steps, setRun, run, ...props }) => {
  const theme = useTheme();
  const transformSteps = steps.map((step) => ({
    ...step,
    hideCloseButton: true,
    disableBeacon: true,
    locale: {
      skip: 'Saltar',
      back: 'Anterior',
      next: 'Siguiente',
      last: 'Fin',
    },
  }));
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setRun(false);
    }
  };
  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous
      run={run}
      scrollToFirstStep
      showProgress
      showSkipButton
      steps={transformSteps}
      disableScrolling
      styles={{
        options: {
          arrowColor: 'white',
          backgroundColor: 'white',
          overlayColor: '#333',
          primaryColor: theme.palette.primary.main,
          textColor: theme.palette.text.primary,
          zIndex: 10000,
        },
        tooltipTitle: {
          ...theme.typography.h3,
          color: theme.palette.primary.main,
        },
        tooltip: {
          ...theme.typography.body1,
        },
        tooltipFooter: {
          ...theme.typography.body1,
        },
        buttonNext: {
          ...theme.typography.body1,
        },
        buttonBack: {
          ...theme.typography.body1,
        },
        buttonSkip: {
          ...theme.typography.body1,
        },
      }}
      {...props}
    />
  );
};
TourGuide.propTypes = {
  setRun: PropTypes.func.isRequired,
  run: PropTypes.bool.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape(
    {
      title: PropTypes.string,
      content: PropTypes.string,
      target: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    },
  )).isRequired,
};
export default TourGuide;
