import React, { useEffect } from 'react';
import { TextField, InputAdornment, Stack, Typography } from '@mui/material';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FingoDatePicker } from '../datePickers';

function TermInput({ value, setValue, defaultDate }) {
  const handleDateChange = (newValue) => {
    setValue({ date: newValue, term: newValue.diff(moment(), 'days') + 1 });
  };

  const handleTermChange = ({ target }) => {
    setValue({ date: moment().add(target.value, 'days'), term: target.value });
  };

  useEffect(() => (
    handleDateChange(defaultDate)
  ), []);

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography>Fecha de pago:</Typography>
        <FingoDatePicker
          disablePast
          gridMinWidth="50%"
          gridProps={{ width: '50%' }}
          onChange={handleDateChange}
          value={value.date}
        />
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography>Plazo de pago:</Typography>
        <TextField
          sx={{ width: '50%' }}
          value={value.term}
          onChange={handleTermChange}
          InputProps={{
            endAdornment: <InputAdornment position="end">días</InputAdornment>,
          }}
          type="number"
        />
      </Stack>
    </Stack>
  );
}

TermInput.propTypes = {
  value: PropTypes.shape(
    {
      date: PropTypes.instanceOf(Date),
      term: PropTypes.number,
    },
  ),
  setValue: PropTypes.func.isRequired,
  defaultDate: PropTypes.instanceOf(moment),
};

TermInput.defaultProps = {
  value: {},
  defaultDate: moment().add(90, 'days'),
};

export default TermInput;
