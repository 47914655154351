import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Stack, Typography, TextField } from '@mui/material';
import Edit from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import { validationTypes } from '../../helpers';
import VerificationTooltip from '../tooltips/VerificationTooltip';

const TitledTextField = ({
  title,
  onChangeInput,
  type,
  disabledEdit,
  attributeName,
  setFormValues,
  formValues,
  errorMsg,
  setErrorMsg,
  verificationAttribute,
}) => {
  const [labelColor, setLabelColor] = useState('white');
  const { t } = useTranslation();
  const onChange = ({ target: { value } }) => {
    if (type) {
      const { truthFunction, message } = validationTypes(value)[type];
      if (!truthFunction() && value) {
        setErrorMsg({ ...errorMsg, [type]: message });
        setLabelColor('primary.light');
      } else {
        setLabelColor('white');
        setErrorMsg({ ...errorMsg, [type]: '' });
      }
    }
    onChangeInput(value);
    setFormValues({ ...formValues, [attributeName]: value });
  };
  useEffect(() => {
    setErrorMsg({});
    setLabelColor('white');
  }, [disabledEdit]);
  return (
    <Grid item xs={12} sm={4}>
      <Stack
        direction="row"
        spacing={1}
        alignItems="center"
        sx={{
          height: 40,
        }}
      >
        <Typography
          noWrap
          variant="h6"
          fontWeight={700}
        >
          {title}
        </Typography>
        {verificationAttribute && (
        <VerificationTooltip verificationAttribute={verificationAttribute} />
        )}
      </Stack>
      <Stack
        direction="row"
      >
        {!onChangeInput ? (
          <Typography
            variant="body1"
            sx={{ py: 0.8 }}
          >
            {formValues[attributeName]}
          </Typography>
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            width="80%"
            spacing={0.5}
          >

            {type === 'phone' && (
            <Typography>
              +{t('Phone prefix')}
            </Typography>
            )}
            <TextField
              variant="standard"
              color="primary"
              value={formValues[attributeName] || ''}
              disabled={disabledEdit || !onChangeInput}
              helperText={errorMsg[type]}
              onChange={onChange}
              sx={{ width: '100%' }}
              InputProps={{
                endAdornment: (
                  !disabledEdit && onChangeInput && (
                  <Edit fontSize="small" color="primary" />)),
                sx: {
                  backgroundColor: labelColor,
                  borderRadius: 1,
                  pl: '4px',
                },
              }}
              FormHelperTextProps={{
                sx: { position: 'absolute', bottom: -20 },
              }}
            />
          </Stack>
        )}
      </Stack>
    </Grid>
  );
};

TitledTextField.propTypes = {
  title: PropTypes.string.isRequired,
  onChangeInput: PropTypes.func,
  type: PropTypes.string,
  disabledEdit: PropTypes.bool,
  attributeName: PropTypes.string.isRequired,
  setFormValues: PropTypes.func.isRequired,
  formValues: PropTypes.shape({
    companyName: PropTypes.string,
    companyRut: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    phoneNumber: PropTypes.string,
    mailProvided: PropTypes.string,
  }).isRequired,
  errorMsg: PropTypes.shape({
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
  setErrorMsg: PropTypes.func.isRequired,
  verificationAttribute: PropTypes.string,
};

TitledTextField.defaultProps = {
  onChangeInput: null,
  type: null,
  disabledEdit: false,
  verificationAttribute: null,
};

export default TitledTextField;
