import { Autocomplete, CircularProgress, FormControl, InputAdornment, TextField } from '@mui/material';
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTextFieldInput } from '../../hooks';
import useSearchPublicEntities from '../../hooks/useSearchPublicEntities';

const AutoCompleteMasterEntity = ({
  setEntity,
  label,
  InputProps,
  textFieldProps,
  autocompleteProps,
  readOnly,
}) => {
  const [search, setSearch] = useTextFieldInput('');
  const [possibleClients, loadingPossibleClients] = useSearchPublicEntities(search);
  const eventChangeClient = useCallback((_, value) => setEntity(value), []);
  return (
    <FormControl fullWidth variant="filled">
      <Autocomplete
        fullWidth
        readOnly={readOnly}
        options={possibleClients}
        onChange={eventChangeClient}
        filterOptions={((options) => options)}
        getOptionLabel={(option) => option.name || option.displayNationalIdentifier}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="filled"
            onChange={setSearch}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <InputAdornment position="end">
                  {loadingPossibleClients && (
                  <CircularProgress size={14} />
                  )}
                </InputAdornment>
              ),
              ...InputProps,
            }}
            {...textFieldProps}
          />
        )}
        {...autocompleteProps}
      />
    </FormControl>
  );
};

AutoCompleteMasterEntity.propTypes = {
  setEntity: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  InputProps: PropTypes.shape(),
  autocompleteProps: PropTypes.shape(),
  textFieldProps: PropTypes.shape(),
  readOnly: PropTypes.string,
};

AutoCompleteMasterEntity.defaultProps = {
  InputProps: {},
  autocompleteProps: {},
  textFieldProps: {},
  readOnly: false,
};

export default AutoCompleteMasterEntity;
